import React, { useState, useEffect, useRef, useReducer } from 'react';
import Api from '../../api/global.service';
import { Link, useParams } from 'react-router-dom';
import { PanelCollapse } from '../../components/panel/panel-collapse.jsx';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LabelCategory from '../../components/label/label-category.jsx';
import { useAuth } from '../../components/content/auth.jsx';
import BlockUi from 'react-block-ui';
import NoPagination from '../../components/pagination/noPagination.jsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SelectOption from '../../components/select/select-option';
import './cuentas-comite.css';
import * as yup from 'yup';
import Moment from 'moment';
import Select from 'react-select';
import colourStyles from '../../utils/colourStyles';
import ComiteCondicionesTable from '../../components/tables/comite-condiciones-table';
import getNextCategoria from '../../utils/getNextCategoria';
import { es } from 'date-fns/locale';
import { formatValues } from '../../utils/formatDate.js';
import Swal from 'sweetalert2';


const reducer = (state, action) => {
  return { ...state, [stateObject[action.type]]: action.payload };
};

const stateObject = {
  /* Global */
  'SET_CUENTA': 'cuenta',
  'SET_BLOCKING': 'blocking',
  'SET_BLOCKING_SAVE': 'blockingSave',
  'SET_VIEW': 'view',
  'SET_FECHA_AUT': 'fechaAut',
  'SET_DATA_CAT':'dataCat',
  'SET_NIVEL_RIESGO':'nivelRiesgo',
  'SET_NIVELES_RIESGO_DATA':'nivelesRiesgoData',
    /* 1.- Aprobado Sin Condiciones */
  'SET_APROB_SC_OBSERVACIONES': 'aprobSCObservaciones',
    /* 2.- Aprobado Con Condiciones */
  'SET_IS_NEW':'isNew',
  'SET_APROB_CC_LIST':'aprobCCList',
  'SET_CONDITION_CONDICION':'conditionCondicion',
  'SET_CONDITION_PLAZO':'conditionPlazo',
  'SET_CONDITION_VERIF_CUMPLIDO':'conditionVerifCumplido',
  'SET_CONDITION_VERIF_FECHA':'conditionVerifFecha',
    /* 3.- Sancionado */
  'SET_SANC_CAT_NUEVA':'sancCatNueva',
  'SET_SANC_FECHA_INICIO':'sancFechaInicio',
  'SET_SANC_FECHA_TERMINO':'sancFechaTermino',
  'SET_SANC_OBSERVACIONES':'sancObservaciones',
  'SET_ERRORS':'errors',
  'SET_ERRORS1':'errors1',
  'SET_ERRORS2':'errors2',
  'SET_ERRORS3':'errors3',
  'SET_ERRORS_CONDICION':'errorsCondicion',
  'SET_FIRMA':'firma',
};

const initialState = {
    /* Global */
  cuenta: {},
  blocking: false,
  blockingSave: false,
  view: -1,
  fechaAut: null,
  dataCat: [],
  nivelRiesgo: null,
  nivelesRiesgoData: [],
      /* 1.- Aprobado Sin Condiciones */
  aprobSCObservaciones: '',
      /* 2.- Aprobado Con Condiciones */
  isNew: false,
  aprobCCList: [],
  conditionCondicion: '',
  conditionPlazo: null,
  conditionVerifCumplido: { value: -1, label: '' },
  conditionVerifFecha: null,
      /* 3.- Sancionado */
  sancCatNueva: null,
  sancFechaInicio: '',
  sancFechaTermino: '',
  sancObservaciones: '',
      /* 4.- Errores */
  errors: {
    condicion: null,
    fecha_aut: null,
    firma_imagen: null,
  },
  errors1: null,
  errors2: null,
  errors3: null,
  errorsCondicion: null,
  firma: null,
};

const ComitesEditar = () => {
  const auth = useAuth();
  const { uuid } = useParams();
  const hiddenFileInput = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  const schemaGlobal = yup.object().shape({
    // cond_total: yup.number().min(0, 'Debe seleccionar una opción'),
    // cond_cumplidas: yup.number().min(0, 'Debe seleccionar una opción'),
    condicion: yup.number().positive('Debe seleccionar una opción'),
    fecha_aut: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .required('Debe seleccionar una fecha'),
    firma_imagen: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .required('Debe adjuntar su firma'),
  });

  const schema1 = yup.object().shape({
    aprob_sc_observaciones: yup.string().required('Describa las observaciones'),
  });

  const schema2 = yup.object().shape({
    aprob_cc_list: yup.number().min(1),
  });

  const schema3 = yup.object().shape({
    sanc_cat_anterior: yup.number().positive('Debe seleccionar una opción'),
    sanc_cat_nueva: yup.number().positive('Debe seleccionar una opción'),
    sanc_fecha_inicio: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .nullable(),
    sanc_fecha_termino: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .nullable(),
  });

  const schemaCondicion = yup.object().shape({
    condicion: yup.string().required('Describa la condición'),
    plazo: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .required('Debe seleccionar una fecha'),
    verif_cumplido: yup.number().nullable(),
    verif_fecha: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .nullable(),
  });

  const dataS3 = [
    { value: 1, label: 'Aprobado sin condiciones' },
    { value: 2, label: 'Aprobado con condiciones' },
    { value: 3, label: 'Sancionado' },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: 'CONDICIÓN ESTABLECIDA',
        accessor: 'condicion',
        sortable: true,
        minWidth: 100,
      },
      {
        Header: 'PLAZO DE CUMPLIMIENTO',
        accessor: 'plazo_str',
        sortable: true,
        minWidth: 60,
      },
    ],
    []
  );

  useEffect(() => {
    // TODO: fetch from backend
    let cat = [];
    if (auth.getCertification() === 'certimex') {
      cat = [
        { value: 7, label: 'Sin Asignar', color: '#777771' },
        { value: 1, label: 'Transición 0', color: '#D87748' },
        { value: 2, label: 'Transición 1', color: '#FFB94C' },
        { value: 3, label: 'Transición 2', color: '#C7AE34' },
        { value: 4, label: 'Orgánico', color: '#4A6700' },
      ] } else {
        cat = [
          { value: 7, label: 'Sin Asignar', color: '#777771' },
          { value: 2, label: 'Transición 1', color: '#FFB94C' },
          { value: 3, label: 'Transición 2', color: '#C7AE34' },
          { value: 5, label: 'Transición 3', color: '#FFDDB0' },
          { value: 6, label: 'Convencional', color: '#8e44ad' },
          { value: 4, label: 'Orgánico', color: '#4A6700' },
        ];
      }

      dispatch({ type: 'SET_DATA_CAT', payload: cat });
      dispatch({ type: 'SET_BLOCKING', payload: true }); 

    Api.getAll('admin/sic/catalog/niveles_riesgo').then(response => {
      const opts = response.data.map(item => {
        return { value: item['id'], label: item['nombre'], color: item['color'] };
      });
      dispatch({ type: 'SET_NIVELES_RIESGO_DATA', payload: opts });

      if (uuid != null) {

            Api.get('comite_evaluaciones_cuenta', uuid)
            .then(response => {
                if (response.data.length > 0) {
                    const data = response.data[0];   
                    if(data.condicion == 1) {
                      dispatch({ type: 'SET_APROB_SC_OBSERVACIONES', payload: data.aprob_sc_observaciones });
                    }
                    if(data.condicion == 3) {
                        dispatch({ type: 'SET_SANC_OBSERVACIONES', payload: data.sanc_observaciones });
                    }
                    dispatch({ type: 'SET_CONDITION_CONDICION', payload: data.condicion });
                    dispatch({ type: 'SET_SANC_CAT_NUEVA', payload: data.condicion });
                    dispatch({ type: 'SET_VIEW', payload: data.condicion });

            Api.get('cuentas', data.cuenta_uuid).then(response=> {
                response.data = response.data.map(function (item) {
                    return {
                      ...item,
                      _fecha_ingreso: item.fecha_ingreso != null ? item.fecha_ingreso.substr(0, 4) : null,
                    };
                  });
                  dispatch({ type: 'SET_CUENTA', payload: response.data[0] });
                  if (response.data[0].id_nivel_riesgo) {
                    dispatch({ type: 'SET_NIVEL_RIESGO', payload: { value: response.data[0].id_nivel_riesgo, label: response.data[0].nivel_riesgo } });
                  }
            })
                }
            dispatch({ type: 'SET_BLOCKING', payload: false });
          })
          .catch(e => {
            // eslint-disable-next-line no-console
            console.log('e', e);
            dispatch({ type: 'SET_BLOCKING', payload: false });
          });
      }
    });
  }, [uuid]);

  const changeView = event => {
    let tempCategory = state.cuenta?.id_cuenta_categoria;
    if (event == 1) {
      tempCategory = getNextCategoria(tempCategory);
    }
    const cat = state.dataCat.find(it => it.value == tempCategory);
    dispatch({ type: 'SET_SANC_CAT_NUEVA', payload: cat });
    dispatch({ type: 'SET_VIEW', payload: event });
    dispatch({ type: 'SET_CONDITION_CONDICION', payload: event });
  };

  const onAddCondition = () => {
    dispatch({ type: 'SET_IS_NEW', payload: true });
    dispatch({ type: 'SET_ERRORS2', payload: null });
    dispatch({ type: 'SET_ERRORS', payload: { ...state.errors, condicion: null} });
    dispatch({ type: 'SET_CONDITION_CONDICION', payload: '' });
    dispatch({ type: 'SET_CONDITION_PLAZO', payload: null });
    dispatch({ type: 'SET_CONDITION_VERIF_CUMPLIDO', payload: { value: -1, label: '' } });
    dispatch({ type: 'SET_CONDITION_VERIF_FECHA', payload: null });
  };

  const onSaveCondition = () => {
    const data = {
      condicion: state.conditionCondicion,
      plazo: state.conditionPlazo,
      verif_cumplido: state.conditionVerifCumplido.value,
      verif_fecha: state.conditionVerifFecha,
    };

    schemaCondicion
      .validate(data, { abortEarly: false })
      .then(() => {
        // Valida
        if (state.errors2) {
          dispatch({ type: 'SET_ERRORS2', payload: null });
        }
        dispatch({ type: 'SET_ERRORS_CONDICION', payload: { condicion: null, plazo: null, verif_cumplido: null, verif_fecha: null } });
        dispatch({ type: 'SET_IS_NEW', payload: false });
        dispatch({ type: 'SET_APROB_CC_LIST', payload: [
          ...state.aprobCCList,
          {
            condicion: state.conditionCondicion,
            plazo: state.conditionPlazo,
            plazo_str: Moment(state.conditionPlazo).format('DD/MMM/yyyy'),
          },
        ]});
      })
      .catch((err) => {
        const errors = err.inner.reduce((map, obj) => {
          map[obj.path] = obj.message;
          return map;
        }, {});
        dispatch({ type: 'SET_ERRORS_CONDICION', payload: errors });
      });
  };

  const onSubmit = () => {
    let validationPromises = [];
    let globalData = {
      id_user: auth.user.id,
      id_cuenta: state.cuenta.id,
      // cond_total: condTotal,
      // cond_cumplidas: condCumplidas,
      condicion: state.view,
      fecha_aut: state.fechaAut,
      firma_imagen: state.firma,
      id_sic: state.cuenta.id_sic,
      sanc_cat_anterior: state.cuenta?.id_cuenta_categoria,
      sanc_cat_nueva: state.sancCatNueva?.value,
      nombre: state.cuenta.nombre,
      ciclo: state.cuenta.ciclo,
      codigo_tx: state.cuenta.codigo_tx ? state.cuenta.codigo_tx : state.cuenta.clave_tx ? state.cuenta.clave_tx : '',
      localidad: state.cuenta.localidad,
      cuenta_categoria: state.cuenta.cuenta_categoria,
    };
  
    if (state.nivelRiesgo && state.nivelRiesgo.value) {
      globalData = {
        ...globalData,
        id_nivel_riesgo: state.nivelRiesgo.value,
        nivel_riesgo: state.nivelRiesgo.label,
      };
    }
  
    validationPromises.push(
      schemaGlobal.validate(globalData, { abortEarly: false }).then(() => {
        // Fase 1 Valida
        dispatch({ type: 'SET_ERRORS', payload: { condicion: null, fecha_aut: null, firma_imagen: null } });
      })
    );
  
    let optionData = {};
  
    // Validar: 1. Aprobada sin condiciones
    if (state.view === 1) {
      optionData = {
        /* 1.- Aprobado Sin Condiciones */
        aprob_sc_observaciones: state.aprobSCObservaciones,
      };
      validationPromises.push(
        schema1.validate(optionData, { abortEarly: false }).then(() => {
          // Valida
          dispatch({ type: 'SET_ERRORS1', payload: {
            aprob_sc_observaciones: null,
          } });
        })
      );
    } else if (state.view === 2) {
      optionData = {
        /* 2.- Aprobado Con Condiciones */
        aprob_cc_list: state.aprobCCList,
      };
      validationPromises.push(
        schema2.validate({ aprob_cc_list: state.aprobCCList.length }, { abortEarly: false })
      );
    } else {
      optionData = {
        /* 3.- Sancionado */
        sanc_observaciones: state.sancObservaciones,
      };
  
      if (state.sancFechaInicio) optionData = { ...optionData, sanc_fecha_inicio: state.sancFechaInicio };
      if (state.sancFechaTermino) optionData = { ...optionData, sanc_fecha_termino: state.sancFechaTermino };
      validationPromises.push(
        schema3.validate(optionData, { abortEarly: false }).then(() => {
          // Valida
          // dispatch({ type: 'SET_ERRORS3', payload: {
          //   sanc_cat_anterior: null,
          //   sanc_cat_nueva: null,
          //   sanc_fecha_inicio: null,
          //   sanc_fecha_termino: null,
          //   sanc_observaciones: null,
          // } });
        })
      );
    }
  
    // Run all validations
    Promise.all(validationPromises)
      .then(() => {
        // Save data
        save({
          ...globalData,
          ...optionData,
        });
        // Redirect
        // window.location.href = `/admin/cuentas/comite/${cuenta.uuid}`;
      })
      .catch((err) => {
        // Handle validation errors
        if(err.inner) {
          let errors = err.inner.reduce((map, obj) => {
            map[obj.path] = obj.message;
            return map;
          }, {});
          dispatch({ type: 'SET_ERRORS', payload: errors });
        } else {
          console.log(err)
        }

        // Set errors based on the schema that failed
        if (state.view === 1) {
          let errors1 = err.inner.reduce((map, obj) => {
            map[obj.path] = obj.message;
            return map;
          }, {});
          dispatch({ type: 'SET_ERRORS1', payload: errors1 });
        } else if (state.view === 2) {
          let errors2 = err.inner.reduce((map, obj) => {
            map[obj.path] = obj.message;
            return map;
          }, {});
          dispatch({ type: 'SET_ERRORS2', payload: errors2 });
        } else if (state.view === 3) {
          let errors3 = err.inner.reduce((map, obj) => {
            map[obj.path] = obj.message;
            return map;
          }, {});
          dispatch({ type: 'SET_ERRORS3', payload: errors3 });
        }
      });
  };

  const save = async (values) => {
    const formData = new FormData();
    const formattedValues = formatValues(values);

    for (let key in formattedValues) {
        if (formattedValues[key] instanceof Array) {
          formData.append(key, JSON.stringify(formattedValues[key]));
        } else {
          formData.append(key, formattedValues[key]);
        }
      }
    dispatch({ type: 'SET_BLOCKING_SAVE', payload: true });
    //CREATE
    await Api.createMultiPart(`comite_evaluaciones_update/${uuid}`, formData)
      .then(response => {
        let data = response.data[0];
        dispatch({ type: 'SET_BLOCKING_SAVE', payload: false });
        Swal.fire({
          icon: 'success',
          title: '',
          text: `Se modificó con éxito la evaluación -> ${data.uuid}`,
        }).then(res => {
          if(res.isConfirmed){
            window.location.href = `/admin/cuentas/perfil/${state.cuenta.uuid}`;
          }
        })
      })
      .catch(e => {
        const message = e.response.data.message ?? e;
        dispatch({ type: 'SET_BLOCKING_SAVE', payload: false });
        Swal.fire({
          icon: 'error',
          title: '',
          text: message,
        });
      });
  };

  const handleChange = event => {
    const file = event.target.files[0];
    if(file) {
      dispatch({ type: 'SET_FIRMA', payload: file });
      dispatch({ type: 'SET_ERRORS', payload: { ...state.errors, firma_imagen: null } });
  };
};

  const handleSelectFile = () => {
    hiddenFileInput.current.click();
  };

  const handlerSelectChange = selectedOption => {
    dispatch({ type: 'SET_NIVEL_RIESGO', payload: selectedOption });
  };

  const handleInput = (event, type) => {
      dispatch({ type: type, payload: event.target.value });
  }

  const handleDateChange = (date, type) => {
    dispatch({ type: type, payload: date });
  };

  return (
    <>
      <div>
        <Breadcrumb>

          <Link to={{ pathname: `/admin/cuentas/perfil/${state.cuenta.uuid}` }}>
            <button type="button" className="btn btn-outline-secondary mt-4" style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-arrow-left"></i> Volver al perfil
            </button>
          </Link>
        </Breadcrumb>

        <div className="row pb-4">
          <div className="col-xl-12">
            <CardContainer height={'100%'}>
              <div className="row">
                <div className="col-xl-12">
                  <PanelCollapse title="COMITÉ DE EVALUACIÓN">
                    <BlockUi tag="div" message="" blocking={state.blockingSave} renderChildren={true}>
                      <div className="row my-4">
                        <div className="col-12">
                          <span className="label-16 mr-2">Rellena los campos consinderando todas las parcelas del socio:</span>
                        </div>
                      </div>
                      {auth.getCertification() === 'certimex' && (
                        <div className="row my-4">
                          <div className="col-3">
                            <span className="label-16 mr-2">El nivel de riesgo de este productor es:</span>
                          </div>
                          <div className="col-3" style={{ position: 'relative' }}>
                            <Select
                              loading={state.blocking}
                              options={state.nivelesRiesgoData}
                              onChange={handlerSelectChange}
                              value={state.nivelRiesgo}
                              styles={colourStyles}
                            />
                            {/* //TODO: missing logic for showing errors */}
                          </div>
                        </div>
                      )}
                      <div className="row my-4">
                        <h3>1. Observaciones, Condiciones o sanciones pasadas del productor.</h3>
                      </div>
                      <div className="row my-4">
                        <div className="col-12" style={{position:'relative'}}>
                          <ComiteCondicionesTable id_cuenta={state.cuenta?.id} blocking={state.blocking} />
                        </div>
                      </div>
                      <div className="row my-4">
                        <div className="col-12">
                          <h3>2. Basándose en la inspección interna el comité considera al productor:</h3>
                          <SelectOption
                            data={dataS3}
                            setFilter={event => {
                              changeView(event.value);
                            }}
                            style={{ width: 250 }}></SelectOption>
                          {state.errors?.condicion && <div className="invalid-feedback">{state.errors?.condicion}</div>}
                        </div>
                      </div>
                      {state.view === 1 && (
                        <div className="row my-4">
                          <div className="col-12">
                            <h3>Observaciones</h3>
                          </div>
                          <div className="col-12">
                            <textarea
                              className="form-control"
                              rows="6"
                              value={state.aprobSCObservaciones}
                              onChange={event => {
                                handleInput(event, 'SET_APROB_SC_OBSERVACIONES');
                              }}
                              placeholder="Describa las observaciones"></textarea>
                            {state.errors1?.aprob_sc_observaciones && <div className="invalid-feedback">{state.errors1?.aprob_sc_observaciones}</div>}
                          </div>
                          <div className="row my-4">
                            <div className="col-4">
                              <h3>Categoría anterior</h3>
                            </div>
                            <div className="col-8">
                              <h3>Nueva categoría</h3>
                            </div>
                            <div className="col-4">
                              <LabelCategory label={state.cuenta.cuenta_categoria} color={state.cuenta.cuenta_categoria_color} />
                            </div>
                            <div className="col-8">
                              <Select
                                loading={state.blocking}
                                options={state.dataCat}
                                onChange={option => dispatch({ type: 'SET_SANC_CAT_NUEVA', value: option})}
                                value={state.sancCatNueva}
                                styles={colourStyles}
                              />
                              {state.errors3?.sanc_cat_nueva && <div className="invalid-feedback">{state.errors3?.sanc_cat_nueva}</div>}
                            </div>
                          </div>
                        </div>
                      )}
                      {state.view === 2 && (
                        <div className="row my-4">
                          <div className="col-10">
                            <NoPagination blocking={false} columns={columns} data={state.aprobCCList} sticky={false} ></NoPagination>
                            {state.errors2 && state.errors2.aprob_cc_list && <div className="invalid-feedback">Agregue al menos una condición</div>}
                          </div>
                          {!state.isNew && (
                            <div className="col-12">
                              <button
                                onClick={() => {
                                  onAddCondition();
                                }}
                                type="button"
                                className="btn btn-outline-secondary btn-sirio"
                                style={{ borderRadius: '20px' }}>
                                <i className="fa fa-plus mr-2"></i> Agregar condición
                              </button>
                            </div>
                          )}
                          {state.isNew && (
                            <>
                              <div className="col-12">
                                <div className="row my-4">
                                  <div className="col-6">
                                    <h3>Condición establecida</h3>
                                  </div>
                                  <div className="col-6">
                                    <h3>Plazo de cumplimiento</h3>
                                  </div>
                                </div>
                                <div className="row my-4">
                                  <div className="col-6">
                                    <input
                                      className="form-control"
                                      value={state.conditionCondicion}
                                      onChange={event => {
                                        dispatch({ type: 'SET_CONDITION_CONDICION', payload: event.target.value });
                                      }}
                                      placeholder="Escribe la condición"></input>
                                    {state.errorsCondicion?.condicion && <div className="invalid-feedback">{state.errorsCondicion?.condicion}</div>}
                                  </div>
                                  <div className="col-6">
                                    <DatePicker
                                      dateFormat={'dd/MMM/yyyy'}
                                      selected={state.conditionPlazo}
                                      locale={es}
                                      onChange={date => {
                                        dispatch({ type: 'SET_CONDITION_PLAZO', payload: date });
                                      }}
                                      className="form-control"
                                      minDate={new Date()}
                                      placeholderText="Seleccione una fecha"
                                    />
                                    {state.errorsCondicion?.plazo && <div className="invalid-feedback">{state.errorsCondicion?.plazo}</div>}
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 text-left">
                                <button
                                  onClick={() => {
                                    dispatch({ type: 'SET_IS_NEW', payload: false });
                                  }}
                                  type="button"
                                  className="btn btn-outline-secondary mr-2"
                                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                                  <i className="fa fa-times"></i> Cancelar
                                </button>
                                <button
                                  onClick={() => {
                                    onSaveCondition();
                                  }}
                                  type="button"
                                  className="btn btn-outline-secondary"
                                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                                  <i className="fa fa-plus"></i> Agregar
                                </button>
                              </div>
                            </>
                          )}
                          <div className="row my-4">
                            <div className="col-4">
                              <h3>Categoría anterior</h3>
                            </div>
                            <div className="col-8">
                              <h3>Nueva categoría</h3>
                            </div>
                            <div className="col-4">
                              <LabelCategory label={state.cuenta.cuenta_categoria} color={state.cuenta.cuenta_categoria_color} />
                            </div>
                            <div className="col-8">
                              <Select
                                loading={state.blocking}
                                options={state.dataCat}
                                onChange={option => handleInput(option, 'SET_SANC_SAT_NUEVA')}
                                value={state.sancCatNueva}
                                styles={colourStyles}
                              />
                              {state.errors3?.sanc_cat_nueva && <div className="invalid-feedback">{state.errors3?.sanc_cat_nueva}</div>}
                            </div>
                          </div>
                        </div>
                      )}
                      {state.view === 3 && (
                        <div>
                          <div className="row my-4">
                            <div className="col-3">
                              <h3>Categoría anterior</h3>
                            </div>
                            <div className="col-3">
                              <h3>Nueva categoría por la sanción</h3>
                            </div>
                            <div className="col-3">
                              <h3>Fecha de inicio de sanción</h3>
                            </div>
                            <div className="col-3">
                              <h3>Fecha de término de sanción</h3>
                            </div>
                            <div className="col-3">
                              <LabelCategory label={state.cuenta.cuenta_categoria} color={state.cuenta.cuenta_categoria_color} />
                            </div>
                            <div className="col-3">
                              <Select
                                loading={state.blocking}
                                options={state.dataCat}
                                onChange={option => handleInput(option, 'SET_SANC_CAT_NUEVA')}
                                value={state.sancCatNueva}
                                styles={colourStyles}
                              />
                              {state.errors3?.sanc_cat_nueva && <div className="invalid-feedback">{state.errors3?.sanc_cat_nueva}</div>}
                            </div>
                            <div className="col-3">
                              <DatePicker
                                selected={state.sancFechaInicio ? state.sancFechaInicio : null}
                                dateFormat={'dd/MMM/yyyy'}
                                onChange={date => { handleDateChange(date, 'SET_SANC_FECHA_INICIO')}}
                                className="form-control"
                                // minDate={new Date()}
                                placeholderText="Seleccione una fecha"
                              />
                              {state.errors3?.sanc_fecha_inicio && <div className="invalid-feedback">{state.errors3?.sanc_fecha_inicio}</div>}
                            </div>
                            <div className="col-3">
                              <DatePicker
                                selected={state.sancFechaTermino ? state.sancFechaTermino : null}
                                dateFormat={'dd/MMM/yyyy'}
                                onChange={date => { handleDateChange(date, 'SET_SANC_FECHA_TERMINO')}}
                                className="form-control"
                                // minDate={new Date()}
                                placeholderText="Seleccione una fecha"
                              />
                              {state.errors3?.sanc_fecha_termino && <div className="invalid-feedback">{state.errors3?.sanc_fecha_termino}</div>}
                            </div>
                          </div>
                          <div className="row my-4">
                            <div className="col-12">
                              <textarea
                                className="form-control"
                                rows="6"
                                value={state.sancObservaciones}
                                onChange={event => {
                                  handleInput(event, 'SET_SANC_OBSERVACIONES');
                                }}
                                placeholder="Describa la causa de la sanción"
                              />
                              {state.errors1?.sanc_observaciones && <div className="invalid-feedback">{state.errors1?.sanc_observaciones}</div>}
                            </div>
                          </div>
                        </div>
                      )}
                      <hr></hr>

                      <div className="row my-4">
                        <div className="col-12">
                          <span className="label-16">Autorización</span>
                        </div>
                      </div>

                      <div className="row my-4">
                        <div className="col-3">
                          <DatePicker
                            selected={state.fechaAut ? state.fechaAut : null}
                            dateFormat={'dd/MMM/yyyy'}
                            onChange={date => {
                              handleDateChange(date, 'SET_FECHA_AUT');
                              // dispatch({ type: 'SET_ERRORS', payload: { ...state.errors, fecha_aut: null } });
                            }}
                            className="form-control"
                            minDate={new Date()}
                            placeholderText="Seleccione una fecha"
                          />
                          {state.errors?.fecha_aut && <div className="invalid-feedback">{state.errors?.fecha_aut}</div>}
                        </div>
                        <div className="col-9">
                          <input
                            type="file"
                            ref={hiddenFileInput}
                            className="btn btn-outline-secondary"
                            onChange={handleChange}
                            style={{ display: 'none' }}
                          />
                          <button
                            onClick={handleSelectFile}
                            type="button"
                            className="btn btn-outline-secondary btn-sirio mr-2"
                            style={{ borderRadius: '20px' }}>
                            <i className="fa fa-upload mr-2"></i> Añadir firma
                          </button>
                          {state.firma && (
                            <span className="label-16">
                              <i className="fa fa-paperclip"></i> {state.firma?.name}
                            </span>
                          )}
                          {!state.firma && <span className="label-16">Seleccione su firma</span>}
                          {state.errors?.firma_imagen && <div className="invalid-feedback">{state.errors?.firma_imagen}</div>}
                        </div>
                      </div>

                      <button
                        onClick={onSubmit}
                        type="submit"
                        disabled={state.blockingSave}
                        className="btn btn-sirio mt-4"
                        style={{ borderRadius: '20px 20px 20px 20px' }}>
                        <i className="fa fa-save"></i> Guardar evaluación
                      </button>
                    </BlockUi>
                  </PanelCollapse>
                </div>
              </div>
            </CardContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComitesEditar;
